<template>
  <v-app>
    <HelloWorld />
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  components: {
    HelloWorld,
  },
  data: () => ({
    //
  }),
};
</script>




<style>
#app {
  background-image: url("https://grupo-cayman.com/wp-content/uploads/2022/09/benelli_mobile_fondo.png");
  background-color: #000000;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
}

@media only screen and (min-width: 600px) {
  #app {
    background-image: url("https://grupo-cayman.com/wp-content/uploads/2022/09/fondo_benelli_land.jpg");
    background-color: #00ad4b;
    background-position: center;
    background-repeat: repeat-y;
    background-size: cover;
  }
}
</style>
