<template>
  <div class="mainComponent">
    <div class="mainFrame text-center">
      <div class="mainToolbar">
        <LogoMoto />
      </div>
      <div cols="12">
        <v-row>
          <v-col cols="12" sm="6">
            <PromocionMoto />
          </v-col>
          <v-col cols="12" sm="6">
            <FormMoto />
          </v-col>
        </v-row>
      </div>
      <v-col cols="12">
        <h5 class="white--text pa-4">
          Términos legales <br />
          Promoción válida solo para los modelos BENELLI: TNT15, TNT150i y 180S.
          La compra de estos modelos incluye: Casco Pro Tork New Liberty 4, Soat
          La Positiva, Trámite de Tarjeta y Placa y 2 años o 20mil km de
          garantía. Sujeto a stock de unidades y promocionales en Tienda. La
          promoción aplica para todas las tiendas del Grupo Cayman a nivel
          nacional.
        </h5>
      </v-col>
    </div>
  </div>
</template>
<script>
import PromocionMoto from "../subComponents/PromocionMoto.vue";
import FormMoto from "../subComponents/FormMoto.vue";
import LogoMoto from "../subComponents/LogoMoto.vue";
export default {
  components: {
    PromocionMoto,
    FormMoto,
    LogoMoto,
  },
};
</script>
<style>
.mainComponent {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainFrame {
  width: 100%;
  max-width: 1200px;
  padding: 8px;
  margin-bottom: 120px;

  /* padding: 200px 0 16px 0; */
}
.mainToolbar {
  width: 100%;
  min-height: 180px;
}
@media only screen and (min-width: 600px) {
  .mainToolbar {
    width: 100%;
    min-height: 120px;
  }
  .mainFrame {
    margin-bottom: 36px;
    padding: 0px;
    /* padding: 160px 0 16px 0; */
  }
}
</style>
