<template>
  <!--   <div class="text-center white--text d-flex justify-center align-center">
 -->
  <div class="mainFormContainer">
    <div class="mainFormUP">
      <div class="mainForm">
        <div class="py-3">
          <h2 style="color: #004a00">¡COTIZA TU BENELLI AQUÍ!</h2>
        </div>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>
            <v-col class="pa-1" cols="12" sm="6">
              <v-select
                v-model="tipoDocumento"
                item-text="descripcion"
                item-value="id"
                :items="itemsTipoDocumento"
                :rules="[(v) => !!v || 'Tipo Documento es requerido']"
                label="Tipo Documento"
                required
                outlined
                hide-details="auto"
                validate-on-blur
                dense
              ></v-select>
            </v-col>
            <v-col class="pa-1" cols="12" sm="6">
              <v-text-field
                v-if="tipoDocumento == 1 || tipoDocumento == 6"
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="numberDocument"
                :rules="documentRules"
                label="Nro Documento"
                type="number"
                required
                dense
              ></v-text-field>
              <v-text-field
                v-else
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="numberDocument"
                :rules="documentRulesOthers"
                label="Nro Documento"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="12">
              <v-text-field
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="name"
                :rules="[(v) => !!v || 'Nombre es requerido']"
                label="Nombres"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="12">
              <v-text-field
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="phone"
                :rules="phoneRules"
                label="Celular"
                type="number"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="12">
              <v-text-field
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="email"
                :rules="emailRules"
                label="Correo Electrónico"
                required
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="12" sm="4">
              <v-select
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="isDepartamento"
                :items="isListDepartamento"
                item-text="descripcion"
                item-value="idDepa"
                :rules="[(v) => !!v || 'Departamento es un campo obligatorio']"
                label="Departamento"
                required
                dense
              ></v-select>
            </v-col>
            <v-col class="pa-1" cols="12" sm="4">
              <v-select
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="isProvincia"
                :items="isListProvincia"
                item-text="descripcion"
                item-value="idProv"
                :rules="[(v) => !!v || 'Provincia es un campo obligatorio']"
                label="Provincia"
                required
                dense
              ></v-select>
            </v-col>
            <v-col class="pa-1" cols="12" sm="4">
              <v-select
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="isDistrito"
                :items="isListDistrito"
                item-text="descripcion"
                item-value="idDist"
                :rules="[(v) => !!v || 'Distrito es un campo obligatorio']"
                label="Distrito"
                required
                dense
              ></v-select>
            </v-col>
            <v-col class="pa-1" cols="12">
              <v-select
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="modelo"
                item-text="descripcion"
                item-value="id"
                :items="listModelos"
                :rules="[(v) => !!v || 'Modelo es un campo obligatorio']"
                label="Modelo"
                required
                dense
              >
              </v-select>
            </v-col>
            <v-col class="pa-1" cols="12">
              <v-select
                outlined
                hide-details="auto"
                validate-on-blur
                v-model="modalidadPago"
                item-text="descripcion"
                item-value="id"
                :items="itemsPago"
                :rules="[(v) => !!v || 'La modalidad de pago es obligatorio']"
                label="Modalidad de Pago"
                required
                dense
              ></v-select>
            </v-col>
            <v-col class="pa-1" cols="12"> </v-col>
            <v-col cols="12"> </v-col>
          </v-row>

          <v-checkbox
            class="white--text"
            v-model="checkbox"
            :rules="[(v) => !!v || 'Marca la casilla para continuar']"
            required
            dense
          >
            <template v-slot:label>
              <span style="color: #004a00"
                >Acepto las
                <a style="color: #004a00" @click="dialog = true"
                  ><strong>Políticas de Privacidad de Datos</strong>
                </a>
                <br />
              </span>
            </template>
          </v-checkbox>
          <v-btn
            :loading="loading"
            :disabled="!valid"
            class="mr-4 white--text"
            style="background-color: #004a00"
            width="100%"
            @click="validate"
          >
            Enviar
          </v-btn>
        </v-form>
      </div>
      <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            POLÍTICA DE PRIVACIDAD
            <v-spacer></v-spacer>
            <v-btn icon class="green" dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-4">
            BENELLI PERÚ, cuya denominación social es CAYMAN S.A.C.,
            identificada con RUC Nº 20380456444, domiciliado en Av. San Luis N°
            1950, distrito de San Borja, Provincia y Departamento de Lima (en
            adelante “GRUPO CAYMAN”) se preocupa por la protección y privacidad
            de los datos personales de sus clientes y público en general. Por
            ello, se ha comprometido a garantizar la absoluta confidencialidad
            de la información que recolecten o les proporciones, en especial
            sobre tus datos personales; para lo cual, emplean altos estándares
            de seguridad, a fin de evitar su alteración, pérdida, tratamiento o
            acceso no autorizado, conforme a lo establecido en la Ley de
            Protección de Datos Personales – Ley Nº 29733, su Reglamento
            aprobado por el Decreto Supremo N° 003-2013-JUS y cualquier otra
            disposición aplicable o modificatorias, sustitutorias y revocatorias
            (en adelante “las normas”).
            <br />
            <br />
            Como benelli perú quiere estar cerca de ti, al aceptar está Política
            de Privacidad, estás aceptando haber leído el contenido de la misma
            y, en consecuencia, otorgas tu consentimiento libre, informado,
            expreso, gratuito e indubitable para que GRUPO CAYMAN pueda dar
            tratamiento y hacer uso de tus datos personales, siendo el Titular
            del Banco de Datos Personales. De acuerdo a ello, en este documento
            te detallamos cuál es nuestra finalidad a fin de que estés
            correctamente informado y tomes conocimiento del uso de tus datos
            personales.
            <br />
            <br />
            Asimismo, es importante mencionar que la suscripción de la presente
            autorización es de carácter libre y voluntaria y que, por tanto, no
            condiciona la adquisición de ninguno de los productos ofrecidos por
            GRUPO CAYMAN o los servicios financieros ofrecidos por su aliado.
            <br />
            <br />
            <span>
              <strong>
                1. ¿Qué información califica como "Datos Personales"?
              </strong>
              <br />
              De acuerdo a lo establecido en las normas, se entiende por "datos
              personales" a toda información, numérica, alfabética, gráfica,
              fotográfica, acústica, sobre hábitos personales, o de cualquier
              otro tipo concerniente a una personal natural que la identifica o
              la hace identificable, a través de medios que puede ser
              razonablemente utilizados.
              <br />

              Por ejemplo la información que lo identifica se refiere a nombres,
              apellidos, nacionalidad, estado civil, documento de identidad,
              ocupación, estudios, domicilio, correo electrónico, teléfono,
              estado de salud, actividad que realiza, ingresos económicos,
              patrimonio, gastos, entre otros
              <br />

              Mientras que la información que lo hace identificable son los
              referidos a rasgos físicos y/o de conducta, voz, huella dactilar,
              imagen, etc.
              <br />
            </span>
            <span>
              <strong> 2. ¿Qué información será recolectada? </strong>
              <br />
              i. Los datos personales que brindes de manera libre, inequívoca y
              voluntaria al momento de efectuar un pedido, realices consultas de
              cotizaciones, envíes solicitudes, contratación de productos o
              servicios de GRUPO CAYMAN a través de cualquiera de sus
              plataformas o cuando accedas a sus sitios web o landings page,
              participes en eventos y/o promociones, organizadas por GRUPO
              CAYMAN o envíes consultas o comuniques incidencias, y en general
              cualquier interacción web, telefónica, presencial, documentaria y
              además de la información que se derive del uso de servicios que
              pudieras tener contratados con GRUPO CAYMAN.
              <br />
              ii. Los datos personales que proporciones a GRUPO CAYMAN o que
              éste los haya obtenido válidamente por terceros, sobre sus
              aspectos laborales, académicos, patrimoniales, migratorios,
              biométricos, incluyendo los datos sensibles relativos a sus
              ingresos económicos y financieros o cualquier otro.
              <br />
              iii. Información que se acumula de manera pasiva como consecuencia
              de la utilización de los productos y/o servicios de GRUPO CAYMAN.
              Esta información se refiere, de manera enunciativa más no
              limitativa, a información detallada sobre el uso de los servicios
              que ofrece GRUPO CAYMAN, mientras navegas en sus plataformas webs,
              sus datos de ubicación, dirección IP e información sobre el
              dispositivo utilizado, modelo de hardware, versión de sistema
              operativo, información sobre software, entre otros.
              <br />
              iv. Datos personales que se encuentren disponibles en fuentes
              accesibles al público. Esta información se refiere a aquella
              contenida en medios de comunicación electrónica, óptica y de otra
              tecnología concebida para facilitar información al público y que
              esté abierto a la consulta general, guías telefónicas, diarios y
              revistas, medios de comunicación social, listas profesionales,
              repertorios de jurisprudencia anonimizados, registros públicos
              administrados por la Superintendencia Nacional de Registros
              Públicos (SUNARP), así como todo registro o banco de datos
              calificado como público conforme a ley y la información que se
              encuentre en las entidades de la Administración Pública y que deba
              ser entregada conforme a la normativa vigente en materia de
              transparencia y acceso a la información pública.
              <br />
              v. Datos obtenidos a través de centrales de información
              crediticia, la cual resulta ser necesaria para elaborar el perfil
              del usuario solicitante (se recopila información sobre
              antecedentes financieros, comerciales, tributarios, laborales,
              entre otros).
              <br />
              vi. Información obtenida a través de redes sociales, redes
              profesionales y/o proveedores de servicios de correo electrónico
              (se utiliza para recopilar información sobre el perfil del usuario
              solicitante, a través de permisos sólo de lectura).
              <br />
            </span>
            <span>
              <strong>
                3. ¿Para qué serán utilizados tus datos personales?
              </strong>
              <br />
              Si brindas tu autorización, tus datos serán tratados de acuerdo a
              las normas señaladas en la introducción, por GRUPO CAYMAN y/o sus
              empresas vinculadas, de manera directa o a través de proveedores,
              ya sea en el país o el extranjero, el cual tendrá las siguientes
              finalidades:
              <br />
              i. Verificar y validar la identidad del cliente y del potencial
              cliente
              <br />
              ii. Evaluar el otorgamiento del(los) productos(s) y/o servicio(s)
              que solicites o dar cumplimiento de los servicios financieros
              ofrecidos por sus aliados.
              <br />
              iii. Realizar evaluaciones financieras, procesamiento de datos,
              formalizaciones contractuales, cobro de deudas, gestión de
              operaciones financieras, evaluar su comportamiento en el sistema
              crediticio y capacidad de pago, remisión de correspondencia, entre
              otros, la misma que podrá ser realizada a través de tercero.
              <br />
              iv. Ofrecerle productos o servicios financieros o relacionados,
              desarrollar acciones comerciales, realizar estudios de mercado,
              elaborar perfiles de compra, enviar o remitir publicidad,
              obsequios, ofertas e información en general (personalizada o
              general) por cualquier medio, lo que se podrá realizar a través de
              terceras personas.
              <br />
              v. Usar, compartir o transferir bajo cualquier título, sus datos
              personales con las empresas vinculadas a GRUPO CAYMAN o lo
              conformen en el futuro, así como con sus socios comerciales o
              cualquier otra empresa, organización o persona natural, tanto
              dentro como fuera del país, nacionales o extranjeros, públicos o
              privados, para que estos le den el tratamiento legal que
              consideren oportuno para sus propios fines.
              <br />
              vi. Actualizar los registros y programas de sistemas o utilizarlos
              en los casos de fusión, escisión o adquisición de GRUPO CAYMAN.
              <br />
              vii. Para fines de auditoría interna, externa o en cumplimiento a
              los requerimientos por parte de cualquier autoridad competente,
              sea policial, judicial o administrativa o para salvaguardar el
              interés público o así como contribuir con la administración de
              justicia.

              <br />
            </span>
            <span>
              <strong> 4. ¿Por cuánto tiempo serán tratados tus datos? </strong>
              <br />
              Tus datos personales se almacenarán mientras su tratamiento sea
              necesario para cumplir con las finalidades descritas; sin exceder
              el plazo de 10 años. Por otro lado, recuerda que puedes, en
              cualquier momento, revocar, rectificar o cancelar el
              consentimiento que nos otorgues, acercándote a nuestra oficina de
              GRUPO CAYMAN indicada inicialmente.
              <br />
            </span>
            <span>
              <strong> 5. COOKIES Y TECNOLOGÍAS SIMILARES </strong>
              <br />
              Para brindar un mejor servicio, GRUPO CAYMAN utiliza Cookies o
              tecnologías similares los cuales que son pequeños archivos que se
              almacenan en las computadoras y que permiten recordar
              características o preferencias de la navegación de los usuarios de
              GRUPO CAYMAN en su página webs. Asimismo, los Cookies se utilizan
              para analizar la experiencia de los usuarios de Internet y de esa
              forma brindarles mejores servicios y mostrar contenido
              personalizado.
              <br />
              De cualquier modo, tú puedes configurar tu navegador para aceptar
              o rechazar la instalación de los Cookies o tecnología similar o
              suprimirlos una vez que hayan finalizado su navegación en nuestros
              sitios webs. Mediante la aceptación de la presente Política de
              Privacidad, consientes al uso de Cookies y tecnologías similares.
              <br />
            </span>
            <span>
              <strong> 6. CONSENTIMIENTO </strong>
              <br />
              Al aceptar esta Política de Privacidad, estas otorgando tu
              consentimiento previo, libre, expreso, inequívoco y gratuito, para
              el tratamiento de tus datos personales para las finalidades
              expuestas anteriormente.
              <br />
              Para el caso de información que califique como sensible, reconoces
              que tu voluntad inequívoca queda demostrada por los siguientes
              actos: (a) El otorgamiento o llenado de tu información personal
              para acceder a cualquier de los servicios de GRUPO CAYMAN, más aún
              cuando, de ser el caso, proporciones tu foto y copia de tus
              documentos para verificar tu identidad; y, (b) haber expresado tu
              aceptación a la presente Política de Privacidad. Tomando ello en
              consideración, tu conducta evidencia que has consentido
              inequívocamente, no admitiéndose dudas al respecto.
              <br />
            </span>
            <span>
              <strong> 7. BANCO DE DATOS </strong>
              <br />
              Los datos personales registrados serán almacenados por GRUPO
              CAYMAN en el banco de datos personales inscrito en el Registro
              Nacional de Protección de Datos Personales, conforme lo establece
              las normas mencionadas en la introducción del presente documento.
              <br />
            </span>
            <span>
              <strong> 8. DERECHOS ARCO </strong>
              <br />
              De acuerdo a lo indicado en el numeral 4, los titulares de datos
              personales o sus representantes legales podrán ejercer los
              derechos que las normas de Protección de Datos Personales les
              otorga para solicitar en cualquier momento el acceso,
              rectificación, cancelación u oposición sobre el uso de sus datos
              personales. Para ello, deberá presentar una solicitud escrita en
              la oficina de GRUPO CAYMAN, descrita en la introducción del
              presente documento, y deberá acompañar la solicitud con una copia
              del documento de identidad del solicitante o documento
              equivalente. En caso de representantes legales, adicionalmente se
              deberá presentar el título que acredite dicha representación.

              <br />

              La presente Política de Privacidad podrá ser modificada por GRUPO
              CAYMAN. De producirse cualquier cambio o modificación de la
              presente Política, el texto vigente de la misma será publicado en
              nuestro portal web.
              <br />
            </span>
            <span>
              <strong> 8. EMPRESAS VINCULADAS A GRUPO CAYMAN </strong>
              <br />
              i. CAYMAN SAC
              <br />
              ii. CORPORACIÓN CAYMAN
            </span>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="response" max-width="350px">
        <v-card>
          <v-card-title class="text-h6"> </v-card-title>
          <v-card-text>
            <h2 class="text-center">¡Gracias por comunicarse con nosotros!</h2>
          </v-card-text>
          <v-card-text>
            <p class="text-center">
              En breve un Asesor Benelli se comunicará con usted.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark @click="response = false">
              ok
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const { VUE_APP_BASIC_USER, VUE_APP_BASIC_PASS, VUE_APP_GEVENT_LABEL } =
  process.env;

export default {
  data: () => ({
    someData: false,
    valid: true,
    loading: false,
    dialog: false,
    response: false,
    idSolicitud: null,
    numberDocument: "",
    documentRules: [
      (v) => !!v || "# Documento es obligatorio",
      (v) => v.length == 8 || "# Documento invalido",
      (v) => /^[0-9]+$/.test(v) || "# Documento debe ser numerico",
    ],
    documentRulesOthers: [
      (v) => !!v || "# Documento es obligatorio",
      (v) => (v.length < 13 && v.length > 8) || "# Documento invalido",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Email no es valido",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Celular es obligatorio",
      (v) => /^[0-9]+$/.test(v) || "Celular debe ser numerico",
      (v) => v.length == 9 || "# Celular invalido",
    ],
    name: "",
    tipoDocumento: 1,
    itemsTipoDocumento: [
      { id: 1, descripcion: "DNI" },
      { id: 4, descripcion: "CE" },
    ],
    modalidadPago: null,
    itemsPago: [
      { id: 4, descripcion: " CONTADO" },
      { id: 6, descripcion: "CREDITO" },
    ],
    listModelos: null,
    idMarca: null,
    modelo: null,
    checkbox: false,
    isDepartamento: null,
    isProvincia: null,
    isDistrito: null,
    isListDepartamento: null,
    isListDistrito: null,
    isListProvincia: null,
  }),

  methods: {
    async validate() {
      try {
        const result = this.$refs.form.validate();
        console.log("result ", result);
        if (result) {
          this.$gtm.trackEvent({
            event: VUE_APP_GEVENT_LABEL + "_SUCCESS",
            category: "CONFIRMATION",
            action: "click",
            label: VUE_APP_GEVENT_LABEL + "_SUCCESS",
            value: "success",
            noninteraction: false, // Optional
          });
          this.loading = true;
          const token = await this.$recaptcha("login");
          console.log("token ", token);

          const { data } = await axios({
            url: `/api/public/gc/prospectos`,
            method: "POST",
            auth: {
              username: VUE_APP_BASIC_USER,
              password: VUE_APP_BASIC_PASS,
            },
            headers: {
              "content-type": "application/json",
            },
            data: {
              captchaResponse: token,
              idProspectoCrediz: null,
              descripcion: this.listModelos.find((x) => x.id == this.modelo)
                .descripcion,
              usuarioCreacion: "SYSTEM",
              usuarioModificacion: null,
              idOrigen: 51,
              idTipoProspecto: 1,
              idProducto: this.modelo,
              idModelo: this.modelo,
              idMarca: this.listModelos.find((x) => x.id == this.modelo)
                .idMarca,
              modelo: this.listModelos.find((x) => x.id == this.modelo)
                .descripcion,
              idTipoPago: this.modalidadPago,
              idMetodoEnvio: null,
              idDepa: this.isDepartamento,
              idDist: this.isDistrito,
              idProv: this.isProvincia,
              idTipoDocumento: this.tipoDocumento,
              nombreCompletos: this.name,
              numeroDocumento: this.numberDocument,
              telefono: this.phone,
              correo: this.email,
              direccion: "",
              idEstado: 31,
              isUbigeoText: 0,
            },
          });
          console.log("data", data);
          this.loading = false;
          this.idSolicitud = data.payload.idProspecto;
          this.response = true;
          this.$refs.form.reset();
        } else {
          this.$gtm.trackEvent({
            event: VUE_APP_GEVENT_LABEL + "_NOTVALID",
            category: "NOTVALID",
            action: "click",
            label: VUE_APP_GEVENT_LABEL + "_NOTVALID",
            value: "NOTVALID",
            noninteraction: false, // Optional
          });
        }
      } catch (error) {
        console.log("error ", error);
        this.$gtm.trackEvent({
          event: VUE_APP_GEVENT_LABEL + "_FAILED",
          category: "ERROR",
          action: "click",
          label: VUE_APP_GEVENT_LABEL + "_FAILED",
          value: "failed",
          noninteraction: false, // Optional
        });
        alert("Ha ocurrido un error Interno");
      }
    },
  },
  computed: {
    relativeProvincia() {
      return this.isDepartamento;
    },
    relativeDistrito() {
      return this.isProvincia;
    },
  },
  watch: {
    async relativeProvincia(v) {
      if (v) {
        console.log("v", v);
        const { data } = await axios({
          url: `/api/private/ubigeo/departamentos/${v}/provincias`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            mode: "cors",
          },
          method: "GET",
          auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },
        });
        console.log("data.payload", data.payload);
        this.isListProvincia = data.payload;
      }
    },
    async relativeDistrito(v) {
      if (v) {
        console.log("v", v);
        this.isDistrito = null;
        const { data } = await axios({
          url: `/api/private/ubigeo/provincias/${v}/distritos`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "GET",
          auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },
        });
        console.log("data.payload", data.payload);
        this.isListDistrito = data.payload;
      }
    },
  },
  async created() {
    //await this.$gtm.trackView("HomePage", "/");
    await this.$recaptchaLoaded();
    const data = await Promise.all([
      axios({
        url: `/api/private/productos`,
        method: "GET",
        auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },
        params: {
          model: "d3d3LnF1aWVyb21pYmVuZWxsaS5wZQ==",
        },
        headers: {
          "content-type": "application/json",
        },
      }),
      axios({
        url: `/api/private/ubigeo/departamentos`,
        method: "GET",

        auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          mode: "cors",
        },
      }),
    ]);
    console.log("data", data);
    this.listModelos = data[0].data.payload;
    data[1].data.payload.pop();
    this.isListDepartamento = data[1].data.payload;
  },
};
</script>
<style>
.mainFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainFormUP {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;
}
.mainForm {
  background: white;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 20px;
  padding: 8px 8px 16px 8px;

  max-width: 400px;
}
@media only screen and (min-width: 600px) {
  .mainForm {
    padding: 8px 16px 24px 16px;
  }
}
</style>
